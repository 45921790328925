import React from 'react';
import { HeadlineInverse, HeadlineInverseSmall } from '../Headline';
import { UserPersonalInformatonWithoutBilling } from '../UserPersonalInformaton';
import { UserConsentManagement } from '../UserConsentManagement';
import './UserAccountSettings.scss';

export const UserAccountSettingsWithoutBilling = () => (
  <div className='user-account-settings'>
    <div className="user-account-settings__headline-section">
      <HeadlineInverse>Account settings</HeadlineInverse>
    </div>
    <div className="user-account-settings__content-row">
      <div className="user-account-settings__content-row-item">
        <HeadlineInverseSmall>Personal Information</HeadlineInverseSmall>
      </div>
      <div className="user-account-settings__content-row-item">
        <HeadlineInverseSmall>Consent Management</HeadlineInverseSmall>
      </div>
    </div>
    <div className="user-account-settings__content-row">
      <div className="user-account-settings__content-row-item">
        <UserPersonalInformatonWithoutBilling />
      </div>
      <div className="user-account-settings__content-row-item">
        <UserConsentManagement />
      </div>
    </div>
  </div>
);
