import React from 'react';
import { UserMenu } from '../UserMenu';
import { UserName } from '../UserName';
import './UserNavigation.scss';

export const UserNavigation = () => (
  <div className="user-navigation">
    <div className="user-navigation__name-section">
      <UserName />
    </div>
    <div className="user-navigation__menu-section">
      <UserMenu />
    </div>
  </div>
);
