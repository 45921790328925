import React from 'react';
import { Content, Footer, FooterNavigation, Header } from '../../components';
import './ProductLayout.scss';

export const ProductLayout = ({ children }) => (
  <>
    <Header />
    <Content>
      {children}
    </Content>
    <FooterNavigation />
    <Footer />
  </>
);
