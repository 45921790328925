import React from 'react';

import { NotOneCompatibleVehicleInformationBox } from '../../components';
import { InformationBoxLayout } from '../../layout';


export const NotOneCompatibleVehiclePage = () => (
  <InformationBoxLayout>
    <NotOneCompatibleVehicleInformationBox />
  </InformationBoxLayout>
);
