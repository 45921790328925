import React from 'react';
import { HeadlineInverse } from '../Headline';
import { PaymentMethodOrderSummary } from '../PaymentMethodOrderSummary';
import { BillingInfoForm } from '../BillingInfoForm';
import './BillingInfoDetail.scss';

export const BillingInfoDetail = () => (
    <div className="billing-info-detail">
      <div className="billing-info-detail__headline-section">
        <HeadlineInverse>Billing information</HeadlineInverse>
      </div>
      <div className="billing-info-detail__inner">
        <div className="billing-info-detail__inner__payment-section">
          <BillingInfoForm />
        </div>
        <div className="billing-info-detail__inner__summary-section">
          <PaymentMethodOrderSummary />
        </div>
      </div>
    </div>
);
