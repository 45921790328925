import React from 'react';
import './Navigation.scss';

export const Navigation = ({isLogged}) => (
  <nav className="navigation">
    {isLogged ?
    <>
    <a href="/" className="navigation__button navigation__button--active">All</a>
    <a href="/" className="navigation__button">Care Connect</a>
    <a href="/" className="navigation__button">Function On Demand</a>
    <a href="/" className="navigation__button">Web Apps</a>
    </>
    : ''}
  </nav>
);