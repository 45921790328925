import React from "react";
import { Button, ButtonSecondaryBack } from "../Button";
import { CheckboxBold, Input } from "../FormElements";
import { HeadlineInverseSmall } from "../Headline";
import { InputSmall, Select } from "../FormElements";
import "./BillingInfoForm.scss";

export const BillingInfoForm = ({ saveText }) => (
  <form className="billing-info-form">
    <div className="billing-info-form__line">
      <Input name="first-name" label="First name" />
    </div>
    <div className="billing-info-form__line">
      <Input name="last-name" label="Last name" />
    </div>
    <div className="billing-info-form__line billing-info-form__line__break">
      <Input name="phone" label="Phone number" />
    </div>
    <HeadlineInverseSmall>Billing address</HeadlineInverseSmall>
    <div className="billing-info-form__line billing-info-form__line--spaced">
      <CheckboxBold label="I'm buying as a company" checked={false} />
    </div>
    <div className="billing-info-form__line">
      <Input name="street" label="Street" />
    </div>
    <div className="billing-info-form__line">
      <InputSmall name="street-no" label="Street No." />
    </div>
    <div className="billing-info-form__line">
      <Input name="city" label="City" />
    </div>
    <div className="billing-info-form__line">
      <InputSmall name="zip" label="ZIP" />
    </div>
    <div className="billing-info-form__line">
      <Select name="country" label="Country">
        <option value="cz">Czech republic</option>
        <option value="sk">Slovakia</option>
      </Select>
    </div>
    <div className="billing-info-form__actions">
      <ButtonSecondaryBack>Payment</ButtonSecondaryBack>
      <Button type="submit">Continue</Button>
    </div>
  </form>
);
