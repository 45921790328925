import React from 'react';
import './ProductItemBox.scss';

export const ProductItemBox = ({
  children,
  type = 'full',
  orientation = 'normal',
  imageSource,
  bgColor,
  textClass,
  headline,
  price,
  currency,
  period,
}) => (
  <article className={`product-item-box product-item-box--${type} product-item-box--${orientation}`}>
    <a href="/" className="product-item-box__link" style={{ backgroundImage : `url(${imageSource})` }}>
      <div className="product-item-box__container">
        <div className="product-item-box__background" style={{ backgroundColor: bgColor }}></div>
        <div className={textClass ? `product-item-box__info product-item-box__info--negative`:'product-item-box__info'}>
          <h2 className="product-item-box__headline">{headline}</h2>
          <p className="product-item-box__description">{children}</p>
          <div className="product-item-box__price-row">
            <span className="product-item-box__price-row__value">{price}<sup> {currency}</sup></span>
            <span className="product-item-box__price-row__period">/ {period}</span>
          </div>
        </div>
      </div>
    </a>
  </article>
);
