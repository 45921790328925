import React from 'react';
import './FooterNavigation.scss';

export const FooterNavigation = () => (
  <div className="footer-navigation">
    <div className="footer-navigation__border"></div>
    <div className="footer-navigation__inner">
      <nav className="footer-navigation__column">
        <h5 className="footer-navigation__column__headline">About shopping</h5>
        <ul>
          <li><a href="/">How to shop</a></li>
          <li><a href="/">Delivery information</a></li>
          <li><a href="/">Payment Options</a></li>
          <li><a href="/">Cheque book</a></li>
        </ul>
      </nav>
      <nav className="footer-navigation__column">
        <h5 className="footer-navigation__column__headline">Your oders</h5>
        <ul>
          <li><a href="/">Order Tracking</a></li>
          <li><a href="/">Claims &amp; Returns</a></li>
          <li><a href="/">FAQs</a></li>
          <li><a href="/">Terms &amp; Conditions</a></li>
        </ul>
      </nav>
      <nav className="footer-navigation__column">
        <h5 className="footer-navigation__column__headline">About us</h5>
        <ul>
          <li><a href="/">ŠKODA Online</a></li>
          <li><a href="/">ŠKODA Auto</a></li>
          <li><a href="/">ŠKODA E-shop partners</a></li>
          <li><a href="/">Privacy Policy</a></li>
        </ul>
      </nav>
      <nav className="footer-navigation__column">
        <h5 className="footer-navigation__column__headline">Contact us</h5>
        <ul>
          <li><a href="tel:+420800600000"><span className="sko-icon sko-icon-phone"></span>+ 420 800 600 000</a></li>
          <li><a href="mailto:info@skoda-auto.com"><span className="sko-icon sko-icon-email"></span>info@skoda-auto.com</a></li>
        </ul>
      </nav>
    </div>
  </div>
);