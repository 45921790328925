import React from 'react';

import { ProductItemBox, ProductList } from '../../components';
import { ProductLayout } from '../../layout';


export const ProductsList = () => (
  <ProductLayout>
    <ProductList headline="Škoda Connect Store" description="Make your car smarter">
      <ProductItemBox type="full" headline="Care Connect" price="99" currency="Kč" period="lifetime" imageSource="/assets/product-items/care-connect-thumb.png" bgColor="#AA6833">
        Vzdálený přístup je nový způsob kontrolování vašeho vozu díky propojení vozu a chytrého telefonu.
      </ProductItemBox>

      <ProductItemBox type="medium" orientation="switch" headline="Infotainment On-Line" price="1 274" currency="Kč" period="rok" imageSource="/assets/product-items/infotaiment-online-thumb.png" bgColor="#64A8A0">
        Služby Infotainment Online vám vždy pomohou dostat se bezpečně a pohodlně do cíle cesty
      </ProductItemBox>

      <ProductItemBox type="small" orientation="switch" headline="Ambient Lighting" price="199" currency="Kč" period="lifetime" imageSource="/assets/product-items/fod-ambient-lighting-thumb.png" bgColor="#2E640B"></ProductItemBox>
      
      <ProductItemBox type="small" headline="Smart Parking" price="99" currency="Kč" period="rok" imageSource="/assets/product-items/webapp-smartparking-thumb.png" bgColor="#1A83D2"></ProductItemBox>
      
      <ProductItemBox type="medium" headline="McDonald’s On The Way" price="290" currency="Kč" period="lifetime" imageSource="/assets/product-items/webapp-mc-donald-thumb.png" bgColor="#F8A623" textClass="negative">
        Díky službě McDonald’s On The Way si nově vychutnáš jídlo z McDonald’s kdykoliv tě napadne. Vyber si restauraci kdekoliv na tvé trase, objednej si jídlo, než tam dorazíš, vše bude připravené.
      </ProductItemBox>

      <ProductItemBox type="medium" headline="High Beam Control" price="290" currency="Kč" period="lifetime" imageSource="/assets/product-items/fod-high-beam-thumb.png" bgColor="#A36BAF">
        Funkce High Beam Control zvyšuje viditelnost na cestách bez oslepování proti jedoucím vozům.
      </ProductItemBox>
      
      <ProductItemBox type="small" headline="Traffic Sign Recognition" price="99" currency="Kč" period="rok" imageSource="/assets/product-items/fod-traffic-sign-thumb.png" bgColor="#5F6366"></ProductItemBox>
    </ProductList>
  </ProductLayout>
);