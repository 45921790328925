import React from 'react';
import { ButtonLink } from '../Button';
import './UserAddress.scss';

export const UserAddressPrimary = () => (
  <div className="user-address">
    <div className="user-address__row">
      <div className="user-address__address-container">
        <p className="user-address__item user-address__item--strong">Argentinska 5</p>
        <p className="user-address__item">170 00 Prague 7</p>
        <p className="user-address__item">Czech Republic</p>
      </div>
      <div className="user-address__status user-address__status--primary">
        Primary address
      </div>
    </div>
    <div className="user-address__row user-address__row--actions">
      <ButtonLink>Edit</ButtonLink>
      <ButtonLink>Delete</ButtonLink>
    </div>
  </div>
);
