import React from 'react';
import { ButtonLinkAdd } from '../Button';
import { HeadlineInverse, HeadlineInverseSmall } from '../Headline';
import { UserAddress, UserAddressPrimary } from '../UserAddress';
import './UserAddresses.scss';

export const UserAddresses = () => (
  <div className='user-addresses'>
    <div className="user-addresses__headline-section">
      <HeadlineInverse>Addresses</HeadlineInverse>
    </div>
    <div className="user-addresses__subheadline-section">
      <HeadlineInverseSmall>Billing addresses</HeadlineInverseSmall>
    </div>
    <ul className="user-addresses__address-list">
      <li className="user-addresses__address-list-item">
        <UserAddressPrimary />
      </li>
      <li className="user-addresses__address-list-item">
        <UserAddress />
      </li>
    </ul>
    <div className="user-addresses__action-section">
      <ButtonLinkAdd>Add new billing address</ButtonLinkAdd>
    </div>
  </div>
);
