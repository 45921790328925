import React from 'react';
import { OrderSummaryDetail, ProgressBar } from '../../components';
import { OrderSummaryLayout } from '../../layout';

export const ShoppingCartOrderSummary = () => (
  <OrderSummaryLayout
    stepNavigation={<ProgressBar step={4}/>}
  >
    <OrderSummaryDetail />
  </OrderSummaryLayout>
);
