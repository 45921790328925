import React from 'react';
import './OrderPriceSummary.scss';

export const OrderPriceSummary = () => (
  <table className='order-price-summary'>
    <tbody>
      <tr>
        <td className='order-price-summary__item order-price-summary__label'>
          Total without VAT
        </td>
        <td className='order-price-summary__item order-price-summary__value'>
          1 007 CZK
        </td>
      </tr>
      <tr>
        <td className='order-price-summary__item order-price-summary__label'>
          Total VAT (21%)
        </td>
        <td className='order-price-summary__item order-price-summary__value'>
          267 CZK
        </td>
      </tr>
      <tr>
        <td className='order-price-summary__item order-price-summary__label order-price-summary__label--strong'>
          Total
        </td>
        <td className='order-price-summary__item order-price-summary__value order-price-summary__value--strong'>
          1 274 CZK
        </td>
      </tr>
    </tbody>
  </table>
);
