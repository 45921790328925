import React from 'react';
import { PaymentMethodOrderPriceSummary } from '../PaymentMethodOrderPriceSummary';
import { Divider } from '../Divider';
import { PaymentMethodOrderProductSummary } from '../PaymentMethodOrderProductSummary';
import './PaymentMethodOrderSummary.scss';

export const PaymentMethodOrderSummary = () => (
  <div className="payment-method-order-summary">
    <p className="payment-method-order-summary__title">Your order</p>
    <Divider></Divider>
    <PaymentMethodOrderProductSummary />
    <PaymentMethodOrderProductSummary />
    <Divider></Divider>
    <div className="payment-method-order-summary__online-payment-box">
      <p className="payment-method-order-summary__text">Card payment online</p>
      <p className="payment-method-order-summary__price">2 345 CZK</p>
    </div>
    <Divider></Divider>
    <PaymentMethodOrderPriceSummary />
  </div>
);
