import React from 'react';
import { Button } from '../Button';
import { HeadlineInverse } from '../Headline';
import { UserVehicleCardCompatible, UserVehicleCardIncompatible } from '../UserVehicleCard';
import './UserVehicles.scss';

export const UserVehiclesIncompatible = () => (
  <div className="user-vehicles">
    <div className="user-vehicles__headline-secion">
      <HeadlineInverse>My Vehicles</HeadlineInverse>
    </div>
    <ul className="user-vehicles__vehicle-list">
      <li className="user-vehicles__vehicle-list-item">
        <UserVehicleCardCompatible />
      </li>
      <li className="user-vehicles__vehicle-list-item">
        <UserVehicleCardIncompatible />
      </li>
    </ul>
    <div className="user-vehicles__actions-secion">
      <Button>Add new vehicle</Button>
    </div>
  </div>
);
