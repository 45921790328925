import React from 'react';
import './UserName.scss';

export const UserName = () => (
  <div className="user-name">
    <span className="user-name__icon" />
    <div className="user-name__container">
      <span className="user-name__name">Lubomír Veselý</span>
      <span className="user-name__email">l.vesely@seznam.cz</span>
    </div>
  </div>
);
