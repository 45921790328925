import React from 'react';
import { ModalBoxProduct, ProductFeatureDetail } from '../../components';
import { ProductLayout } from '../../layout';

export const PopUpVideo = () => (
  <>
    <ProductLayout />
    <ModalBoxProduct>
      <ProductFeatureDetail />
    </ModalBoxProduct>
  </>
);
