import React from 'react';
import { ProductFeatureIcon } from './ProductFeatureIcon';

import ProductData from './data/ProductData.json'

import './ProductDetail.scss';

const productFeatures = ProductData;

export const ProductDetail = ({
  children,
  type,
  imageSource,
  bgColor,
  textClass,
  headline,
  price,
  currency,
  period,
}) => {
  return (
    <div className="product-detail">
      <div className="product-detail__container">
        <div className={textClass ? `product-detail__info product-detail__info--negative`:'product-detail__info'}>
          <h2 className="product-detail__headline">{headline}</h2>
          <p className="product-detail__description">{children}</p>

          <div className="product-detail__price-row">
            <span className="product-detail__price-row__value">{price}<sup> {currency}</sup></span>
            <span className="product-detail__price-row__period">/ {period}</span>
          </div>

          {productFeatures[type] ? (
            <div className="product-detail__features">
              <h3>Includes the following features:</h3>
              <div className="product-detail__feature-list">
                {
                  productFeatures[type].map((item)=>{
                    return <ProductFeatureIcon key={`feature-${item.id}`} icon={`/assets/feature-icons/care-connect/${item.icon}`} title={item.title} />
                  })
                }
              </div>
            </div>
          ) : ''}

          <div className="product-detail__compatibility-note">
            <strong>Note:</strong> The availability of services depends on the equipment of your vehicle and the country in which you are located. The Gracenote service is available for vehicles manufactured from 05/2017. The Online independent heating service is available for vehicles with auxiliary heating manufactured from 05/2017. The Car Unlocking & Locking service is available for vehicles manufactured after 11/2018.
          </div>
        </div>
      </div>

      <div className="product-detail__background" style={{ backgroundColor: bgColor }}></div>
      <div className="product-detail__image" style={{ backgroundImage : `url(${imageSource})` }}></div>
    </div>
  )
};

