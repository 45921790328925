import React from 'react';
import { Footer, FooterNavigation, Header } from '../../components';
import './InformationBoxLayout.scss';

export const InformationBoxLayout = ({children}) => (
  <>
    <Header />
    <div className="information-box-layout__inner">
      <div className="information-box-layout__image-section">
        <h1 className="information-box-layout__title">Škoda Connect Store</h1>
        <h2 className="information-box-layout__subtitle">Make your car smarter</h2>
      </div>
      <div className="information-box-layout__content-section">
        {children}
      </div>
    </div>
    <FooterNavigation />
    <Footer />
  </>
);
