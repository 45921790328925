import React from 'react';
import './FormElements.scss';

export const Select = ({ label, name, children }) => (
  <div className="form__input-container form__input-container--select">
    <select id={name} name={name} className="form__select">
      {children}
    </select>
    <label htmlFor={name} className="form__label" >{label}</label>
    <span className="form__select-arrow-icon sko-icon sko-icon-beak-down"></span>
  </div>
);
