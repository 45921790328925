import React from 'react';
import ProductImage from '../../static/assets/product.png';
import './OrderProductSummary.scss';

export const OrderProductSummary = () => (
  <div className='order-product-summary'>
    <img className='order-product-summary__image' src={ProductImage} alt="product-illustrative" />
    <div className='order-product-summary__info-section'>
      <p className='order-product-summary__product-name'>Infotainment Online</p>
      <p className='order-product-summary__car'>Terka's Fabia (VIN: 135FG4567UZHGFZ54)</p>
      <p className='order-product-summary__timing'>1 year prolongation</p>
    </div>
    <div className='order-product-summary__price'>1 274 CZK</div>
  </div>
);
