import React from 'react';

import { NoVehicleInformationBox } from '../../components';
import { InformationBoxLayout } from '../../layout';


export const NoVehiclePage = () => (
  <InformationBoxLayout>
    <NoVehicleInformationBox />
  </InformationBoxLayout>
);
