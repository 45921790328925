import React from 'react';
import youtubeScreen from '../../static/assets/youtubeScreen.png';
import graphIcon from '../../static/assets/graphIcon.svg';
import './ProductFeatureDetail.scss';

export const ProductFeatureDetail = () => (
  <div className="product-feature-detail">
    <div  className="product-feature-detail__icon"><img src="/assets/feature-icons/care-connect/lock-unlock.png" alt="icon of graph" /></div>
    <p className="product-feature-detail__headline">
      Driving Data
    </p>
    <span class="product-feature-detail__close-icon sko-icon sko-icon-cross-thin"></span>
    <p className="product-feature-detail__text">
      Whether you want to know the parameters of your last trip in order to control consumption or simply get a summary of all your previous trips, driving data is the ideal solution. You can retrieve current vehicle data, such as average fuel consumption, average speed or distance traveled, via the ŠKODA Connect portal or the ŠKODA Connect application.
    </p>
    <div className="text-center">
      <img src={youtubeScreen} alt="youtube video img" />
    </div>
  </div>
);
