import React from 'react';
import { UserNavigation, UserVehicles } from '../../components';
import { UserAccount } from '../../layout';

export const UserAccountVehicles = () => (
  <UserAccount
    navigation={<UserNavigation />}
    children={<UserVehicles />}
  />
);
