import React from 'react';
import { Footer, FooterNavigation, Header } from '../../components';
import './OrderSummaryLayout.scss';

export const OrderSummaryLayout = ({ stepNavigation, children }) => (
  <>
    <Header />
    <content className="order-summary-layout">
      {stepNavigation ?
      <div className="order-summary-layout__nav-section">
        {stepNavigation}
      </div>
      : ''}
      <div className="order-summary-layout__content-section">
        <div className="order-summary-layout__content-wrapper">
          {children}
        </div>
      </div>
    </content>
    <FooterNavigation />
    <Footer />
  </>
);
