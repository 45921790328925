import React from 'react';
import './Footer.scss';

export const Footer = () => (
  <div className="footer">
    <div className="footer__inner">
      <div className="footer__block">
        <span className="footer__copyright">&copy; ŠKODA AUTO a.s. 2020</span>
      </div>
      <div className="footer__block">
        <div className="footer__social-icons">
            <a href="https://twitter.com/skodacz" target="_blank" rel="noreferrer">
              <span data-icon="" className="social-icon icon twitter"></span>
            </a>
            <a href="https://www.facebook.com/skoda.cz" target="_blank" rel="noreferrer">
              <span data-icon="" className="social-icon icon facebook"></span>
            </a>
            <a href="https://www.youtube.com/user/skodaceskarepublika" target="_blank" rel="noreferrer">
              <span data-icon="" className="social-icon icon youtube"></span>
            </a>
            <a href="https://www.instagram.com/skodacr/" target="_blank" rel="noreferrer">
              <span data-icon="" className="social-icon icon instagram"></span>
            </a>
        </div>
      </div>
      <div className="footer__block">&nbsp;</div>
    </div>
  </div>
);