import React from 'react';
import './UserMenu.scss';

const UserMenuItem = ({ children }) => (
  <li className="user-menu__item">
    <a className="user-menu__link" href="#">
      {children}
    </a>
  </li>
);

const UserMenuItemActive = ({ children }) => (
  <li className="user-menu__item user-menu__item--active">
    <a className="user-menu__link" href="#">
      {children}
    </a>
  </li>
);

const UserMenuItemSpaced = ({ children }) => (
  <li className="user-menu__item user-menu__item--spaced">
    <a className="user-menu__link" href="#">
      {children}
    </a>
  </li>
);

export const UserMenu = () => (
  <ul className="user-menu">
    <UserMenuItemActive>My Orders</UserMenuItemActive>
    <UserMenuItem>Addresses</UserMenuItem>
    <UserMenuItem>Account Settings</UserMenuItem>
    <UserMenuItem>My Vehicles</UserMenuItem>
    <UserMenuItemSpaced>Log Out</UserMenuItemSpaced>
  </ul>
);
