import React from 'react';
import { Button } from '../Button';
import { HeadlineInverse } from '../Headline';
import { OrderList } from '../OrderList';
import { Pagination } from '../Pagination';
import { Tabs } from '../Tabs';
import './Orders.scss';

export const Orders = () => (
  <div className="orders">
    <div className="orders__headline-section">
      <HeadlineInverse>My Orders</HeadlineInverse>
    </div>

    <div className="orders__tabs-section">
      <Tabs />
    </div>

    <div className="orders__order-list-section">
      <OrderList />
    </div>

    <div className="orders__actions-section">
      <Button>Load more</Button>

      <span className="orders__pagination">
        <Pagination />
      </span>
    </div>
  </div>
);
