import React from 'react';
import { PaymentMethodDetail, ProgressBar } from '../../components';
import { ShoppingCartLayout } from '../../layout';

export const ShoppingCartPaymentMethod = () => (
  <ShoppingCartLayout
    stepNavigation={<ProgressBar step={2} />}>
    <PaymentMethodDetail />
  </ShoppingCartLayout>
);
