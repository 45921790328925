import React from 'react';
import { ProductDetail } from '../../components';
import { ProductLayout } from '../../layout';

export const ProductDetailCare = () => (
  <ProductLayout>
    <ProductDetail headline="Care Connect" type="care-connect" price="99" currency="Kč" period="lifetime" imageSource="/assets/product-items/care-connect-full-v1.png" bgColor="#AA6833">
      Vzdálený přístup je nový způsob kontrolování vašeho vozu díky propojení vozu a chytrého telefonu.
    </ProductDetail>
  </ProductLayout>
);