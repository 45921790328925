import React from 'react';
import { HeadlineInverse } from '../Headline';
import { UserAddressForm } from '../UserAddressForm';
import './UserAddressEdit.scss';

export const UserAddressEdit = () => (
  <div className="user-address-add">
    <div className="user-address-add__headline-section">
      <HeadlineInverse>Edit billing address</HeadlineInverse>
    </div>
    <div className="user-address-add__form-section">
      <UserAddressForm saveText="Save changes" />
    </div>
  </div>
);
