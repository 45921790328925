import React from 'react';
import { UserNavigation, UserVehicles } from '../../components';
import { UserVehicleEnrollDialog } from '../../components/UserVehicleEnrollDialog/UserVehicleEnrollDialog';
import { UserAccount } from '../../layout';

export const UserAccountVehiclesWithEnrollDialog = () => (
  <>
    <UserAccount
      navigation={<UserNavigation />}
      children={<UserVehicles />}
    />
    <UserVehicleEnrollDialog />
  </>
);
