import React from 'react';
import { Button, ButtonSecondary } from '../Button';
import { Input, InputSmall, Select } from '../FormElements';
import './UserAddressForm.scss';

export const UserAddressForm = ({ saveText }) => (
  <form className="user-address-form">
    <div className="user-address-form__line">
      <Select name="country" label="Country">
        <option value="cz">Czech republic</option>
        <option value="sk">Slovakia</option>
      </Select> 
    </div>
    <div className="user-address-form__line">
      <Input name="street" label="Street" /> 
    </div>
    <div className="user-address-form__line">
      <InputSmall name="street-no" label="Street No." /> 
    </div>
    <div className="user-address-form__line">
      <Input name="city" label="City" /> 
    </div>
    <div className="user-address-form__line">
      <InputSmall name="zip" label="ZIP" /> 
    </div>
    <div className="user-address-form__actions">
      <ButtonSecondary>Cancel</ButtonSecondary>
      <Button type="submit">{saveText || 'Save'}</Button>
    </div>
  </form>
);
