import React from 'react';
import { Checkbox } from '../FormElements';
import { Link } from '../Link';
import './OrderSummaryUserConsentManagement.scss';

export const OrderSummaryUserConsentManagement = () => (
  <div className="order-summary-user-consent-management">
    <Checkbox
      label="I agree to marketing activities"
      checked={false}
    />
    <p className="order-summary-user-consent-management__disclaimer">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a lorem ut leo imperdiet mattis at non nisl. <Link>Suspendisse vehicula molestie</Link> scelerisque. Etiam vitae nibh velit. Vivamus lorem sem, auctor vitae velit efficitur, consequat laoreet est. Aenean quis enim faucibus, rhoncus arcu vel, euis.
    </p>
    <Checkbox
      label="I agree to ŠKODA AUTO's "
      checked={false}
      link="Terms and Conditions"
    />
  </div>
);
