import React from 'react';
import './ProductFeatureIcon.scss';

export const ProductFeatureIcon = ({title,icon}) => (
  <a href="/" className="product-feature-icon">
    <div className="product-feature-icon__button"><img src={icon} alt={title} /></div>
    <span className="product-feature-icon__description">{title}</span>
  </a>
  
);
