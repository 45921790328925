import React from 'react';
import './CustomSelect.scss';
import { createCarSelectSelectedIndicator, createCustomSelect, createCarSelectOption } from './custom-select';

export class CarSelect extends React.Component {
  state = {};

  constructor(){
    super();
    this.ref = React.createRef();
  }

  handleRerender = () => {
    if (this.ref.current) {
      createCustomSelect(this.ref.current, 'car-select', createCarSelectSelectedIndicator, createCarSelectOption);
      this.setState({rendered: true})
    }
  }

  // IN THYMELEAF use just the HTML below without RENDER button and the custom-select.js file, and follow the instructions there to update it to classic JS

  render() {
    return (
      <>
        <span className="car-select custom-select" ref={this.ref}>
          <select>
            <option value="0" data-name="Terka's Fabia" data-vin="12CSFED45TBRG43AA" data-compatible="1" data-active-till="14. 2. 2020" data-image-src="assets/car.png">Terka's Fabia</option>
            <option value="1" data-name="Scala Ambition" data-vin="13234VFG4G54G45GQ" data-compatible="0"data-image-src="assets/car.png">Scala Ambition</option>
            <option value="2" data-name="Fabia Classic" data-vin="24F34F3F43T54DWE4" data-compatible="1" data-image-src="assets/car.png" >Fabia Classic</option>
            <option value="3" data-name="Terka's Fabia 2" data-vin="12CSFED45TBRG43AA" data-compatible="1" data-active-till="14. 2. 2020" data-image-src="assets/car.png">Terka's Fabia 2</option>
            <option value="4" data-name="Terka's Fabia 3" data-vin="12CSFED45TBRG43AA" data-compatible="1" data-active-till="14. 2. 2020" data-image-src="assets/car.png">Terka's Fabia 3</option>
            <option value="5" data-name="Not selectable" data-vin="because of disabled attr" data-compatible="0"data-image-src="assets/car.png" disabled>Scala Ambition</option>
          </select>
        </span>
        {this.props.selectedIncompatible && (
          <div className="car-select__disclaimer">
            Your <span className="car-select__disclaimer--strong">Scala Ambition</span> is not compatible with Care Connect
          </div>
        )}
        {this.props.selectedEarly && (
          <div className="car-select__disclaimer">
            Service will be available for prolongation in 6 months before expiration.
          </div>
        )}
        {!this.state.rendered && <button onClick={this.handleRerender}>RENDER</button>}
      </>
    )
  } 
};