import React from 'react';
import { ButtonLink } from '../Button';
import './UserPersonalInformaton.scss';

export const UserPersonalInformatonWithoutBilling = () => (
  <div className="user-personal-information">
    <p className="user-personal-information__line">
      <span className="user-personal-information__line-title">Email</span>
      l.vesely@seznam.cz
    </p>
    <div className="user-personal-information__line user-personal-information__line--actions">
      <ButtonLink>Update email</ButtonLink>
    </div>
    <p className="user-personal-information__line">
      <span className="user-personal-information__line-title">Name</span>
      Lubomír Veselý
    </p>
    <p className="user-personal-information__line">
      <span className="user-personal-information__line-title">Phone</span>
      +420 724 788 556
    </p>
    <div className="user-personal-information__line user-personal-information__line--actions">
      <ButtonLink>Add billing info</ButtonLink>
      <ButtonLink>Update</ButtonLink>
    </div>
  </div>
);
