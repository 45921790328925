import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";


import './App.scss';
import { Content, Header } from './components';
import { ProductDetailCare, ProductDetailFOD, ProductDetailWebApp, ProductsList, UserAccountAddressAdd, UserAccountAddressEdit, UserAccountAddresses, UserAccountAddressesWithDialog, UserAccountInformationEdit, UserAccountInformationEditCollapsed, UserAccountOrderDetail, UserAccountOrderDetailWarning, UserAccountOrders, UserAccountSettings, UserAccountSettingsWithoutBilling, UserAccountVehicles, UserAccountVehiclesIncompatible, UserAccountVehiclesWithEnrollConfirm, UserAccountVehiclesWithEnrollDialog, ShoppingCartBundle, ShoppingCartPaymentMethod, ShoppingCartBillinginfo, ShoppingCartOrderSummary, LoginPage, ErrorPage, PopUpVideo, ShoppingCartConfirmationActivation, NotLoggedInPage, NoVehiclePage, NotOneCompatibleVehiclePage, NotManyCompatibleVehiclesPage } from './pages';
import { CarSelect, LengthSelect, CarSelectOneOption, LengthSelectOneOption, ToCartButton, LengthSelectDisabled } from './components';

const ProductDetailElems = () => (
  <div style={{padding: '100px', paddingBottom: '500px', backgroundColor: 'darkgoldenrod'}}>
    <div style={{margin: '20px'}}>
      <CarSelectOneOption compatible={false}/>
      <CarSelectOneOption compatible={true} />
      <CarSelect />
    </div>
    <div style={{margin: '20px'}}>
      <CarSelect selectedIncompatible={true} />
    </div>
    <div style={{margin: '20px'}}>
      <CarSelect selectedEarly={true} />
    </div>
    <div style={{margin: '20px'}}>
      <LengthSelectOneOption />
      <LengthSelect />
      <LengthSelectDisabled />
    </div>
    <div style={{margin: '20px'}}>
      <ToCartButton>Prolong</ToCartButton>
      <ToCartButton>Add to cart</ToCartButton>
      <ToCartButton disabled={true}>This item is already in cart</ToCartButton>
    </div>
  </div>
);

const PageSelect = () => (
  <>
    <Header />
    <Content>
    <ul>
      <li><Link to="/error">Error - Homepage</Link></li>
      <li><Link to="/login">Login - Homepage</Link></li>
      <li><Link to="/products-list">Product Lists - Homepage</Link></li>
      <li><Link to="/product-detail-care">Product Detail - Care Connect</Link></li>
      <li><Link to="/product-detail-fod">Product Detail - Function on Demand</Link></li>
      <li><Link to="/product-detail-pop-up-video">Product Detail - Pop up window</Link></li>
      <li><Link to="/product-detail-webapp">Product Detail - Web App (McDonalds)</Link></li>
      <li><Link to="/product-detail-elems">Product detail - form elements</Link></li>
      <li><Link to="/account-orders">User account - orders list</Link></li>
      <li><Link to="/account-order-detail">User account - order detail</Link></li>
      <li><Link to="/account-order-detail-warning">User account - order detail with warning</Link></li>
      <li><Link to="/account-addresses">User account - addresses</Link></li>
      <li><Link to="/account-address-add">User account - address add</Link></li>
      <li><Link to="/account-address-edit">User account - address edit</Link></li>
      <li><Link to="/account-addresses-dialog">User account - address delete dialog</Link></li>
      <li><Link to="/account-settings">User account - settings</Link></li>
      <li><Link to="/account-settings-wo-billing">User account - settings without billing information</Link></li>
      <li><Link to="/account-settings-edit-collapsed">User account - personal informaton edit collapsed</Link></li>
      <li><Link to="/account-settings-edit">User account - personal informaton edit</Link></li>
      <li><Link to="/account-vehicles">User account - vehicles</Link></li>
      <li><Link to="/account-vehicles-incompatible">User account - vehicles incompatible</Link></li>
      <li><Link to="/account-vehicles-enroll">User account - vehicle entroll dialog</Link></li>
      <li><Link to="/account-vehicles-enroll-confirm">User account - vehicle entroll confirm dialog</Link></li>
      <li><Link to="/shopping-cart-bundle">Shopping Cart - 1. Cart Bundle</Link></li>
      <li><Link to="/shopping-cart-payment-method">Shopping Cart - 2. Payment Method</Link></li>
      <li><Link to="/shopping-cart-billing-info">Shopping Cart - 3. Billing Info</Link></li>
      <li><Link to="/shopping-cart-order-summary">Shopping Cart - 4. Order Summary</Link></li>
      <li><Link to="/shopping-cart-confirmation-activation">Shopping Cart - 5. Confirmation Activation</Link></li>
      <li><Link to="/info-box-not-logged-in">Information box - not logged in</Link></li>
      <li><Link to="/info-box-no-vehicle">Information box - no vehicle</Link></li>
      <li><Link to="/info-box-not-one-compatible-vehicle">Information box - no compatible vehicle (of 1 enrolled)</Link></li>
      <li><Link to="/info-box-not-many-compatible-vehicles">Information box - no compatible vehicle (of more enrolled)</Link></li>
    </ul>
    </Content>
  </>
);

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/error"><ErrorPage /></Route>
        <Route path="/login"><LoginPage /></Route>
        <Route path="/products-list"><ProductsList /></Route>
        <Route path="/product-detail-care"><ProductDetailCare /></Route>
        <Route path="/product-detail-fod"><ProductDetailFOD /></Route>
        <Route path="/product-detail-pop-up-video"><PopUpVideo /></Route>
        <Route path="/product-detail-webapp"><ProductDetailWebApp /></Route>
        <Route path="/account-orders"><UserAccountOrders /></Route>
        <Route path="/account-order-detail"><UserAccountOrderDetail /></Route>
        <Route path="/account-order-detail-warning"><UserAccountOrderDetailWarning /></Route>
        <Route path="/account-addresses"><UserAccountAddresses /></Route>
        <Route path="/account-address-add"><UserAccountAddressAdd /></Route>
        <Route path="/account-address-edit"><UserAccountAddressEdit /></Route>
        <Route path="/account-addresses-dialog"><UserAccountAddressesWithDialog /></Route>
        <Route path="/account-settings"><UserAccountSettings /></Route>
        <Route path="/account-settings-wo-billing"><UserAccountSettingsWithoutBilling /></Route>
        <Route path="/account-settings-edit-collapsed"><UserAccountInformationEditCollapsed /></Route>
        <Route path="/account-settings-edit"><UserAccountInformationEdit /></Route>
        <Route path="/account-vehicles"><UserAccountVehicles /></Route>
        <Route path="/account-vehicles-incompatible"><UserAccountVehiclesIncompatible /></Route>
        <Route path="/account-vehicles-enroll"><UserAccountVehiclesWithEnrollDialog /></Route>
        <Route path="/account-vehicles-enroll-confirm"><UserAccountVehiclesWithEnrollConfirm /></Route>
        <Route path="/shopping-cart-bundle"><ShoppingCartBundle /></Route>
        <Route path="/shopping-cart-payment-method"><ShoppingCartPaymentMethod /></Route>
        <Route path="/shopping-cart-billing-info"><ShoppingCartBillinginfo /></Route>
        <Route path="/shopping-cart-order-summary"><ShoppingCartOrderSummary /></Route>
        <Route path="/shopping-cart-confirmation-activation"><ShoppingCartConfirmationActivation /></Route>
        <Route path="/product-detail-elems"><ProductDetailElems /></Route>
        <Route path="/info-box-not-logged-in"><NotLoggedInPage /></Route>
        <Route path="/info-box-no-vehicle"><NoVehiclePage /></Route>
        <Route path="/info-box-not-one-compatible-vehicle"><NotOneCompatibleVehiclePage /></Route>
        <Route path="/info-box-not-many-compatible-vehicles"><NotManyCompatibleVehiclesPage /></Route>
        <Route path="/"><PageSelect /></Route>
      </Switch>
    </Router>
  );
};

export default App;
