import React from 'react';
import { VehicleInformationShort } from '../VehicleInformation';
import './InformationBox.scss';

export const NotManyCompatibleVehiclesInformationBox = () => (
  <div className="information-box information-box--spaced">
    <div className="information-box__vehicle-row information-box__vehicle-row--list">
      <VehicleInformationShort compatible={false} />
      <VehicleInformationShort compatible={false} />
      <VehicleInformationShort compatible={false} />
      <VehicleInformationShort compatible={false} />
      <VehicleInformationShort compatible={false} />
    </div>
    <p className="information-box__headline-text">None of your vehicles are compatible with any available products</p>
  </div>
);
