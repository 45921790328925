import React from 'react';
import './OneOption.scss';

export const CarSelectOneOption = ({ compatible }) => (
  <span className="custom-select-one-option car-select-one-option">
    <img className="car-select-one-option__item-image" src="assets/car.png" />
    <div className={`car-select-one-option__item-badge car-select-one-option__item-badge--${compatible ? 'success' : 'error'} sko-icon sko-icon-${compatible ? 'checkmark' : 'cross'}`} />
    <p className="car-select-one-option__item-name">Fabia Classic</p>
    <p className="car-select-one-option__item-active">{compatible ? 'Active till 14. 2. 2020' : 'Not compatible'}</p>
  </span>
);
