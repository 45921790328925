import React from 'react';
import './OrderSummaryPrice.scss';

export const OrderSummaryPrice = () => (
  <table className='order-summary-price'>
    <tbody>
      <tr>
        <td className='order-summary-price__item order-summary-price__label'>
          Total without VAT
        </td>
        <td className='order-summary-price__item order-summary-price__value'>
          1 007 CZK
        </td>
      </tr>
      <tr>
        <td className='order-summary-price__item order-summary-price__label'>
          Total VAT (21%)
        </td>
        <td className='order-summary-price__item order-summary-price__value'>
          267 CZK
        </td>
      </tr>
      <tr>
        <td className='order-summary-price__item order-summary-price__label order-summary-price__label--strong'>
          Total
        </td>
        <td className='order-summary-price__item order-summary-price__value order-summary-price__value--strong'>
          1 274 CZK
        </td>
      </tr>
    </tbody>
  </table>
);
