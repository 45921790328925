import React from 'react';
import { HeadlineInverse } from '../Headline';
import { UserInformationForm } from '../UserInformationForm';
import './UserInformationEdit.scss';

export const UserInformationEdit = () => (
  <div className="user-information-edit">
    <div className="user-information-edit__headline-section">
      <HeadlineInverse>Edit user information</HeadlineInverse>
    </div>
    <div className="user-information-edit__content-section">
      <UserInformationForm />
    </div>
  </div>
);
