import React from 'react';
import './OrderList.scss';

const OrderBox = () => (
  <li className="order-box">
    <div className="order-box__item order-box__item--strong order-box__item--middle">9871340</div>
    <div className="order-box__item order-box__item--small">Created: 24. 12. 2019</div>
    <div className="order-box__item order-box__item--small order-box__item--right">100 247 CZK</div>
    <div className="order-box__item order-box__item--row order-box__item--wide order-box__item--right">
      <div className="order-box__status order-box__status--success">Delivered</div>
      <span className="order-box__next-arrow sko-icon sko-icon-beak-right"></span>
    </div>
  </li>
);

const OrderBoxWarning = () => (
  <li className="order-box">
    <div className="order-box__item order-box__item--strong order-box__item--middle">9871340</div>
    <div className="order-box__item order-box__item--small">Created: 7. 3. 2019</div>
    <div className="order-box__item order-box__item--small order-box__item--right">1 247 CZK</div>
    <div className="order-box__item order-box__item--row order-box__item--wide order-box__item--right">
      <div className="order-box__status order-box__status--warning">Your order is being prepared</div>
      <span className="order-box__next-arrow sko-icon sko-icon-beak-right"></span>
    </div>
  </li>
);

export const OrderList = () => (
  <ul className="order-list">
    <OrderBox />
    <OrderBoxWarning />
    <OrderBox />
    <OrderBox />
    <OrderBox />
    <OrderBox />
    <OrderBox />
    <OrderBox />
    <OrderBox />
    <OrderBox />
  </ul>
);



