import React from 'react';
import { Footer, FooterNavigation, Header } from '../../components';
import './LoginPageLayout.scss';

export const LoginPageLayout = ({children}) => (
  <>
    <Header />
    <div className="login-box-layout__inner">
      <div className="login-box-layout__content-section">
        {children}
      </div>
    </div>
    <FooterNavigation />
    <Footer />
  </>
);
