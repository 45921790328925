import React from 'react';
import { Button } from '../Button';
import { DialogLight } from '../Dialog';
import { Divider } from '../Divider';
import { HeadlineInverseSmall } from '../Headline';
import Car from '../../static/assets/Car.png';
import './UserVehicleEnrollConfirmDialog.scss';

export const UserVehicleEnrollConfirmDialog = () => (
  <DialogLight>
    <div className="user-vehicle-enroll-confirm-dialog__headline-section">
      <HeadlineInverseSmall>Enroll new vehicle</HeadlineInverseSmall>
      <span className="user-vehicle-enroll-confirm-dialog__close-icon sko-icon sko-icon-cross-thin"></span>
    </div>
    <Divider />
    <div className="user-vehicle-enroll-confirm-dialog__content-section">
      <img className="user-vehicle-enroll-confirm-dialog__image" src={Car} alt="car-illustrative" />
      <div className="user-vehicle-enroll-confirm-dialog__text">
        <HeadlineInverseSmall>Your vehicle is successfully enrolled.</HeadlineInverseSmall>
      </div>
      <div className="user-vehicle-enroll-confirm-dialog__actions">
        <Button>Done</Button>
      </div>
    </div>
  </DialogLight>
);
