import React from 'react';
import './FormElements.scss';

export const CheckboxBold = ({ label, checked }) => (
  <label className="checkbox__container">
    <input className="checkbox__input" type="checkbox" defaultChecked={checked ? true : false}/>
    <span className="checkbox__checkmark"></span>
    <span className="checkbox__bold-text">{label}</span>
  </label>
);
