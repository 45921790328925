import React from 'react';
import { ButtonLink } from '../Button';
import './UserAddress.scss';

export const UserAddress = () => (
  <div className="user-address">
    <div className="user-address__row">
      <div className="user-address__address-container">
        <p className="user-address__item user-address__item--strong">Narodni 21</p>
        <p className="user-address__item">110 00 Prague 1</p>
        <p className="user-address__item">Czech Republic</p>
      </div>
      <div className="user-address__status">
        <ButtonLink>Set as primary</ButtonLink>
      </div>
    </div>
    <div className="user-address__row user-address__row--actions">
      <ButtonLink>Edit</ButtonLink>
      <ButtonLink>Delete</ButtonLink>
    </div>
  </div>
);
