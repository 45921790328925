import React from 'react';
import './ProgressBar.scss';

export const ProgressBar = ({step}) => {
  return (
    <div className="progress-bar">
      <div className="progress-bar__inner">
        <ol className="progress-bar__wrapper">
          <li className={`progress-bar__item${(step > 1 ? ' progress-bar__item--done': (step === 1? ' progress-bar__item--active':''))}`}>
            <span className="progress-bar__item__label">Shopping Cart</span>
          </li>
          <li className={`progress-bar__item${(step > 2 ? ' progress-bar__item--done': (step === 2? ' progress-bar__item--active':''))}`}>
            <span className="progress-bar__item__label">Payment method</span>
          </li>
          <li className={`progress-bar__item${(step > 3 ? ' progress-bar__item--done': (step === 3? ' progress-bar__item--active':''))}`}>
            <span className="progress-bar__item__label">Billing information</span>
          </li>
          <li className={`progress-bar__item${(step > 4 ? ' progress-bar__item--done': (step === 4? ' progress-bar__item--active':''))}`}>
            <span className="progress-bar__item__label">Summary</span>
          </li>
        </ol>
      </div>
    </div>
  )
};
