import React from 'react';
import { InformationBoxLight } from '../../components';
import { LoginForm } from '../../components/LoginForm/LoginForm';

import { LoginPageLayout } from '../../layout';


export const LoginPage = () => (
  <>
    <LoginPageLayout>
    <InformationBoxLight>
      <LoginForm />
    </InformationBoxLight>
    </LoginPageLayout>
  </>
);