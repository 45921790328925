import React from 'react';
import { UserInformationEdit, UserNavigation } from '../../components';
import { UserAccount } from '../../layout';

export const UserAccountInformationEdit = () => (
  <UserAccount
    navigation={<UserNavigation />}
    children={<UserInformationEdit />}
  />
);
