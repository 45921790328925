import React from 'react';
import './ModalBoxProduct.scss';

const ModalHeader = ({title}) => (
  <div className="modal-box-product__header">
    <h3 className="modal-box-product__header__title">{title}</h3>
  </div>
);

export const ModalBoxProduct = ({
  headerTitle,
  children,
  useCloseButton = false
}) => (
  <>
    <div className="modal-box-product__container">
      <div className="modal-box-product__inner">
        {headerTitle ? <ModalHeader title={headerTitle}></ModalHeader> : ''}
        {useCloseButton ?
          <button className="modal-box-product__close">
            <span data-icon="" class="icon icon-close-large-white"></span>
          </button> : ''}
        <div className="modal-box-product__content">
          {children}
        </div>
      </div>
      <div className="modal-box-product__background"></div>
    </div>
  </>
);
