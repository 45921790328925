import React from 'react';
import { Button, ButtonLink } from '../Button';
import './InformationBox.scss';

export const NoVehicleInformationBox = () => (
  <div className="information-box">
    <p className="information-box__headline-text">No vehicle found in your Garage</p>
    <p className="information-box__text">In order to display products compatible with your vehicle, it needs to be enrolled using a VIN code.</p>
    <div className="information-box__action-wrapper">
      <Button>Enroll vehicle now</Button>
    </div>
    <ButtonLink>Log into different account</ButtonLink>
  </div>
);
