import React from 'react';
import { HeadlineInverse } from '../Headline';
import { PaymentMethodIndentPayment } from '../PaymentMethodIndentPayment';
import { PaymentMethodOrderSummary } from '../PaymentMethodOrderSummary';
import './PaymentMethodDetail.scss';

export const PaymentMethodDetail = () => (
  <div className="payment-method-detail">
    <div className="payment-method-detail__headline-section">
      <HeadlineInverse>Payment method</HeadlineInverse>
    </div>
    <div className="payment-method-detail__inner">
      <div className="payment-method-detail__inner__payment-section">
        <PaymentMethodIndentPayment />
      </div>
      <div className="payment-method-detail__inner__summary-section">
        <PaymentMethodOrderSummary />
      </div>
    </div>
  </div>
);
