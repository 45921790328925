import React from 'react';
import './FormElements.scss';

export const Checkbox = ({ label, checked, link }) => (
  <label className="checkbox__container">
    <input className="checkbox__input" type="checkbox" defaultChecked={checked ? true : false}/>
    <span className="checkbox__checkmark"></span>
    {label}
    {link ? <a href="#" className="checkbox__container--link">{link}</a> : ''}
  </label>
);
