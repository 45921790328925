import React from 'react';
import { ButtonSecondary } from '../Button';
import Car from '../../static/assets/Car.png';
import './UserVehicleCard.scss';

export const UserVehicleCardIncompatible = () => (
  <div className="user-vehicle-card">
    <div className="user-vehicle-card__compatibility-badge user-vehicle-card__compatibility-badge--error" />
    <img className="user-vehicle-card__image" src={Car} alt='car-illustrative' />
    <div className="user-vehicle-card__content">
      <p className="user-vehicle-card__car-name">Terka's Fabia</p>
      <p className="user-vehicle-card__car-vin">VIN: 1234567SDFW23SD</p>
      <p className="user-vehicle-card__car-status user-vehicle-card__car-status--error">Not compatible with connectivity</p>
      <div className="user-vehicle-card__actions">
        <ButtonSecondary>
          View in garage
          <span className="user-vehicle-card__external-icon sko-icon sko-icon-arrow-away" />
        </ButtonSecondary>
      </div>
    </div>
  </div>
);
