import React from 'react';
import ProductImage from '../../static/assets/product.png';
import './ShoppingCartProductSummary.scss';

export const ShoppingCartProductSummary = () => (
  <div className='shopping-cart-product-summary'>
    <img className='shopping-cart-product-summary__image' src={ProductImage} alt="product-illustrative" />
    <div className='shopping-cart-product-summary__info-section'>
      <p className='shopping-cart-product-summary__product-name'>Infotainment Online</p>
      <p className='shopping-cart-product-summary__car-type'>Scala Ambition</p>
      <p className='shopping-cart-product-summary__car-vin'>VIN: 863R3684112454RT5</p>
    </div>
    <p className='shopping-cart-product-summary__timing'>1 year prolongation</p>
    <div className='shopping-cart-product-summary__price-section'>
      <p className='shopping-cart-product-summary__price-section__price'>1 274</p>
      <p className='shopping-cart-product-summary__price-section__price__currency'>CZK</p>
      <span class="shopping-cart-product-summary__trash-icon"></span>
    </div>
  </div>
);
