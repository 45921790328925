import React from 'react';
import { UserInformationEditCollapsed, UserNavigation } from '../../components';
import { UserAccount } from '../../layout';

export const UserAccountInformationEditCollapsed = () => (
  <UserAccount
    navigation={<UserNavigation />}
    children={<UserInformationEditCollapsed />}
  />
);
