import React from 'react';
import './OrderSummaryDetailGrid.scss';

export const OrderSummaryDetailGrid = () => (
  <div className="order-summary-detail-grid">
    <div className="order-summary-detail-grid__row">
      <div className="order-summary-detail-grid__box">
        <div className="order-summary-detail-grid__item order-summary-detail-grid__item--strong order-summary-detail-grid__item--spaced">Billing address</div>
        <div className="order-summary-detail-grid__item order-summary-detail-grid__item--strong">Lubomír Veselý</div>
        <div className="order-summary-detail-grid__item">Národní 21</div>
        <div className="order-summary-detail-grid__item">110 00 Praha 1</div>
        <div className="order-summary-detail-grid__item">Czech Republic</div>
      </div>
      <div className="order-summary-detail-grid__box">
        <div className="order-summary-detail-grid__item order-summary-detail-grid__item--spaced">Your email</div>
        <div className="order-summary-detail-grid__item order-summary-detail-grid__item--strong order-summary-detail-grid__item--spaced">l.vesely@seznam.cz</div>
        <div className="order-summary-detail-grid__item order-summary-detail-grid__item--spaced">Phone</div>
        <div className="order-summary-detail-grid__item order-summary-detail-grid__item--strong order-summary-detail-grid__item--spaced">+420 724 558 999</div>
      </div>
    </div>
  </div>
);
