import React from 'react';
import { UserAddressDeleteDialog, UserAddresses, UserNavigation } from '../../components';
import { UserAccount } from '../../layout';

export const UserAccountAddressesWithDialog = () => (
  <>
    <UserAccount
      navigation={<UserNavigation />}
      children={<UserAddresses />}
    />
    <UserAddressDeleteDialog />
  </>
);
