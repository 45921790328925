// REMOVE THIS FROM JS WHEN TRANSFERING TO THYMELEAF
// simple array to hold custom select React references
const customRefs = [];

// short function to simplify DOM element creation
const createElement = (tag, classList, content) => {
  const element = document.createElement(tag);
  element.setAttribute('class', classList);
  element.innerHTML = content || '';
  return element;
}

// close all custom select boxes in the document, except the select box refrenced by elmt
const closeAllSelect = (elmt) => {
  // const customSelects = document.getElementsByClassName('custom-select');
  const customSelects = customRefs; // replace with line above
  for (let i = 0; i < customSelects.length; i++) {
    if (customSelects[i] === elmt) continue;
    if (!customSelects[i]) continue; // can be removed in classic JS
    const optionsContainer = customSelects[i].getElementsByClassName('custom-select__options-container')[0];
    optionsContainer.classList.add('custom-select__options-container--hidden');

    const selectedIndicator = customSelects[i].getElementsByClassName('custom-select__selected-item')[0];
    selectedIndicator.classList.remove('custom-select__selected-item--active');

    const arrowIcon = customSelects[i].getElementsByClassName('custom-select__arrow-icon')[0];
    arrowIcon.classList.remove('custom-select__arrow-icon--active')
  }
}

// on click anywhere in the document close all opened selects
document.addEventListener('click', () => closeAllSelect(null));

// ===== CAR SELECT HELPERS =====

const getActiveClassAndText = (itemRef) => {
  const activeProp = itemRef.getAttribute('data-active-till');
  const compatibleProp = itemRef.getAttribute('data-compatible') === "1";

  if (!activeProp && !compatibleProp) {
    return {variant: 'error', text: 'Not compatible'};
  } else if (!activeProp && compatibleProp) {
    return {variant: 'success', text: 'Compatible'};
  } else {
    return {variant: 'success', text: `Active till ${activeProp}`};
  }
}

export const createCarSelectSelectedIndicator = (selected, selectedOptionRef) => {
  const image = createElement('img', 'car-select__item-image')
  image.setAttribute('src', selectedOptionRef.getAttribute('data-image-src'))
  selected.appendChild(image);

  const compatible = selectedOptionRef.getAttribute('data-compatible') === "1";
  const iconClass = `sko-icon sko-icon-${compatible ? 'checkmark' : 'cross'}`;
  selected.appendChild(createElement(
    'div',
    `car-select__item-badge car-select__item-badge--${compatible ? 'success' : 'error'} ${iconClass}`
  ));

  selected.appendChild(createElement('p', 'car-select__item-name', selectedOptionRef.getAttribute('data-name')));

  const {variant: activeClassVariant, text: activeText} = getActiveClassAndText(selectedOptionRef);
  selected.appendChild(createElement('p', `car-select__item-active car-select__item-active--${activeClassVariant}`, activeText));

  return selected;
}

export const createCarSelectOption = (option, optionRef) => {
  const compatible = optionRef.getAttribute('data-compatible') === "1";
  if (!compatible){
    option.classList.add('car-select__option--disabled');
  }

  const image = createElement('img', 'car-select__item-image')
  image.setAttribute('src', optionRef.getAttribute('data-image-src'))
  option.appendChild(image);

  const iconClass = `sko-icon sko-icon-${compatible ? 'checkmark' : 'cross'}`;
  option.appendChild(createElement(
    'div',
    `car-select__item-badge car-select__item-badge--${compatible ? 'success' : 'error'} ${iconClass}`
  ));

  option.appendChild(createElement('p', 'car-select__item-name', optionRef.getAttribute('data-name')));
  option.appendChild(createElement('p', 'car-select__item-vin', `VIN: ${optionRef.getAttribute('data-vin')}`));

  const {variant: activeClassVariant, text: activeText} = getActiveClassAndText(optionRef);
  option.appendChild(createElement('p', `car-select__item-active car-select__item-active--${activeClassVariant}`, activeText));

  return option;
}

// ===== YEAR SELECT HELPERS =====

export const createLengthSelectSelectedIndicator = (selected, selectedOptionRef) => {
  selected.innerHTML = selectedOptionRef.getAttribute('data-text');
}

export const createLengthSelectOption = (option, optionRef) => {
  option.innerHTML = optionRef.getAttribute('data-text');
}

// ===== CREATE CUSTOM SELECT BOX =====
// when transferin to thymeleaf call this function manually for all custom select classes on the page as shown on the row below

// const customCarSelects = document.getElementsByClassName('car-select');
// customCarSelects.forEach((select) => createCustomSelectBox(select, 'car-select', createCarSelectSelectedIndicator, createCarSelectOption));
// const customLengthSelects = document.getElementsByClassName('length-select');
// customLengthSelects.forEach((select) => createCustomSelectBox(select, 'length-select', createLengthSelectSelectedIndicator, createLengthSelectOption));

export const createCustomSelect = (containerRef, classBase, createSelectedIndicator, createOption) => {
  customRefs.push(containerRef); // REMOVE THIS

  const container = containerRef;
  const select = container.getElementsByTagName('select')[0];
  const optionsCount = select.length;

  //  mark the container to hide the original select
  container.classList.add('custom-select--transformed');

  // create indicator of the selected item
  const selectedIndicator = createElement('div', `custom-select__selected-item ${classBase}__selected-item`);
  createSelectedIndicator(selectedIndicator, select.options[select.selectedIndex]);
  if (select.getAttribute('disabled') !== null) {
    selectedIndicator.classList.add('custom-select__selected-item--disabled');
  }
  container.appendChild(selectedIndicator);

  // create container for options
  const optionsContainer = document.createElement('div');
  optionsContainer.setAttribute('class', `custom-select__options-container custom-select__options-container--hidden ${classBase}__options-container`);

  // for each of the original options create element inside of the container
  for(let i = 0; i < optionsCount; i++) {
    const optionElem = select.options[i];
    const option = createElement('div', `custom-select__option ${classBase}__option`);
    if (optionElem.getAttribute('disabled') !== null){
      option.classList.add('custom-select__option--disabled');
    }
    if (i === select.selectedIndex) {
      option.classList.add('custom-select__option--selected');
    }
    option.setAttribute('value', optionElem.getAttribute('value'));

    createOption(option, optionElem)

    option.addEventListener('click', (e) => {
      if (optionElem.getAttribute('disabled') === null){
        const selectedValue = option.getAttribute('value');
        const selected = option.parentNode.previousSibling;
        const options = option.parentNode.children;
        for (let i = 0; i < optionsCount; i++) {
          if (select.options[i].getAttribute('value') === selectedValue) {
            select.selectedIndex = i;
            selected.innerHTML = '';
            createSelectedIndicator(selected, optionElem);
            options[i].classList.add('custom-select__option--selected');
          } else {
            options[i].classList.remove('custom-select__option--selected');
          }
        }
      } else {
        e.stopPropagation();
      }
    });

    optionsContainer.appendChild(option);
  }

  container.appendChild(optionsContainer);
  container.appendChild(createElement('span', 'sko-icon sko-icon-beak-down custom-select__arrow-icon'));

  container.addEventListener('click', (e) => {
    if (container.getElementsByClassName('custom-select__selected-item--disabled').length > 0) return;

    e.stopPropagation();

    closeAllSelect(container);
    const optionsContainer = container.getElementsByClassName('custom-select__options-container')[0];
    optionsContainer.classList.toggle('custom-select__options-container--hidden');

    const selectedIndicator = container.getElementsByClassName('custom-select__selected-item')[0];
    selectedIndicator.classList.toggle('custom-select__selected-item--active');

    const arrowIcon = container.getElementsByClassName('custom-select__arrow-icon')[0];
    arrowIcon.classList.toggle('custom-select__arrow-icon--active')
  });
}