import React from 'react';
import { ButtonSecondary, Button } from '../Button';
import { CheckboxBold, Input } from '../FormElements';
import './UserInformationForm.scss';

export const UserInformationForm = () => (
  <form className="user-information-form">
    <div className="user-information-form__line">
      <Input name="first-name" label="First name" /> 
    </div>
    <div className="user-information-form__line">
      <Input name="last-name" label="Last name" /> 
    </div>
    <div className="user-information-form__line">
      <Input name="phone" label="Phone number" /> 
    </div>
    <div className="user-information-form__line user-information-form__line--spaced">
      <CheckboxBold label="I'm buying as a company" checked={true} /> 
    </div>
    <div className="user-information-form__intended">
      <div className="user-information-form__line">
        <Input name="company-name" label="Company name" /> 
      </div>
      <div className="user-information-form__line">
        <Input name="company-id-no" label="ID #" /> 
      </div>
      <div className="user-information-form__line">
        <Input name="company-vat-no" label="VAT #" optional={true} /> 
      </div>
    </div>
    <div className="user-information-form__actions">
      <ButtonSecondary>Cancel</ButtonSecondary>
      <Button type="submit">Save changes</Button>
    </div>
  </form>
);
