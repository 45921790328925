import React from 'react';
import './ProductList.scss';

export const ProductList = ({
  children,
  headline,
  description,
}) => (
  <div className="product-list">
    <div className="product-list__background"></div>
    <div className="product-list__container">
      <div className="product-list__container__inner">
        <h2 className="product-list__headline">{headline}</h2>
        <p className="product-list__description">{description}</p>
        <div className="product-list__items">
          {children}
        </div>
      </div>
    </div>
  </div>
);