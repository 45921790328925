import React from 'react';
import { ShoppingCartDetail, ProgressBar } from '../../components';
import { ShoppingCartLayout } from '../../layout';

export const ShoppingCartBundle = () => (
  <ShoppingCartLayout
    stepNavigation={<ProgressBar step={1} />}>
    <ShoppingCartDetail />
  </ShoppingCartLayout>
);
