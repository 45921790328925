import React from 'react';
import { VehicleInformation } from '../VehicleInformation';
import './InformationBox.scss';

export const NotOneCompatibleVehicleInformationBox = () => (
  <div className="information-box information-box--spaced">
    <div className="information-box__vehicle-row">
      <VehicleInformation compatible={false} />
    </div>
    <p className="information-box__headline-text">You vehicle is not compatible with any available products</p>
  </div>
);
