import React from 'react';
import { HeadlineInverse, HeadlineInverseSmall } from '../Headline';
import { PaymentMethodOrderProductSummary } from '../PaymentMethodOrderProductSummary';
import { OrderSummaryPrice } from '../OrderSummaryPrice';
import { Divider } from '../Divider';
import { Button, ButtonSecondaryBack} from '../Button';
import { OrderSummaryDetailGrid } from '../OrderSummaryDetailGrid';
import { OrderSummaryUserConsentManagement } from '../OrderSummaryUserConsentManagement';
import './OrderSummaryDetail.scss';

export const OrderSummaryDetail = () => (
  <div className="order-summary-detail">
    <div className="order-summary-detail__headline">
      <HeadlineInverse>Order Summary</HeadlineInverse>
    </div>
    <div className="order-summary-detail__title">
      <HeadlineInverseSmall>Your order</HeadlineInverseSmall>
    </div>
    <Divider />
    <PaymentMethodOrderProductSummary />
    <PaymentMethodOrderProductSummary />
    <Divider />
    <div className="order-summary-detail__title order-summary-detail__title--padding">
      <HeadlineInverseSmall>Payment</HeadlineInverseSmall>
    </div>
    <Divider />
    <div className="order-summary-detail__online-payment-box">
      <p className="order-summary-detail__online-payment-box__text">Card payment online</p>
      <p className="order-summary-detail__online-payment-box__price">2 345 CZK</p>
    </div>
    <div className="order-summary-detail__title order-summary-detail__title--padding">
      <HeadlineInverseSmall>Billing info</HeadlineInverseSmall>
    </div>
    <OrderSummaryDetailGrid />
    <div className="order-summary-detail__divider-space">
      <Divider />
    </div>
    <div className="order-summary-detail__price-section">
      <OrderSummaryPrice />
    </div>
    <OrderSummaryUserConsentManagement />
    <div className="order-summary-detail__button-section">
      <ButtonSecondaryBack>Payment</ButtonSecondaryBack>
      <Button>Place order</Button>
    </div>
  </div>
);
