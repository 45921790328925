import React from 'react';
import './Dialog.scss';

export const DialogLight = ({ children }) => (
  <div className='dialog__overlay'>
    <div className='dialog dialog--light'>
      {children}
    </div>
  </div>
);
