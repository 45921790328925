import React from 'react';
import './Pagination.scss';

const PageIndicator = ({ children }) => <button className="pagination__item">{children}</button>;

const PageIndicatorActive = ({ children }) => <button className="pagination__item pagination__item--active">{children}</button>;

const NextArrow = () => (
  <button className="pagination__item">
    <span className="pagination__next-arrow sko-icon sko-icon-beak-right"></span>
  </button>
);

export const Pagination = () => (
  <div className="pagination">
    <PageIndicatorActive>1</PageIndicatorActive>
    <PageIndicator>2</PageIndicator>
    <PageIndicator>3</PageIndicator>
    <PageIndicator>4</PageIndicator>
    <PageIndicator>5</PageIndicator>
    <NextArrow />
  </div>
);
