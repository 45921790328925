import React from 'react';
import './FormElements.scss';

export const Password = ({ label, name, optional }) => (
  <div className="form__floating-input-container">
    <input id={name} name={name} type="password" className="form__floating-input" placeholder="placeholder" />
    <label htmlFor={name} className="form__floating-label" >{label}</label>
    {optional && <div className="form__optional-indicator">Optional</div>}
  </div>
);
