import React from 'react';
import { Button, ButtonLink } from '../Button';
import './InformationBox.scss';

export const NotLoggedInInformationBox = () => (
  <div className="information-box">
    <p className="information-box__text">SKODA CONNECT STORE is available only for SKODA customers with an compatible vehicle. Please log into your account.</p>
    <div className="information-box__action-wrapper">
      <Button>Log in</Button>
    </div>
    <div className="information-box__divider"></div>
    <ButtonLink>Create new account</ButtonLink>
  </div>
);
