import React from 'react';
import './ModalBox.scss';

const ModalHeader = ({title}) => (
  <div className="modal-box__header">
    <h3 className="modal-box__header__title">{title}</h3>
  </div>
);

export const ModalBox = ({
  headerTitle,
  children,
  useCloseButton = false
}) => (
  <>
    <div className="modal-box__container">
      <div className="modal-box__inner">
        {headerTitle ? <ModalHeader title={headerTitle}></ModalHeader> : ''}
        {useCloseButton ?
          <button className="modal-box__close">
            <span data-icon="" class="icon icon-close-large-white"></span>
          </button> : ''}
        <div className="modal-box__content">
          {children}
        </div>
      </div>
      <div className="modal-box__background"></div>
    </div>
  </>
);