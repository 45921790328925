import React from 'react';
import './Tabs.scss';

const Tab = ({ children, active = false }) => (
  <li className="tabs__tab">
    <a href="#" tabindex="0" className={`tabs__tab-link${active ? ' tabs__tab-link--active' : ''}`}>
      {children}
    </a>
  </li>
);

export const Tabs = () => (
  <ul className="tabs">
    <Tab active={true}>All orders</Tab>
    <Tab>{'Parts & merch'}</Tab>
    <Tab>Connectivity</Tab>
    <Tab>Powerpass</Tab>
  </ul>
);
