import React from 'react';
import { Error, ModalBox } from '../../components';

import { ProductLayout } from '../../layout';


export const ErrorPage = () => (
  <>
  <ProductLayout />
  <ModalBox>
    <Error />
  </ModalBox>
  </>
);