import React from 'react';
import { AccordionElement } from '../Accordion';
import { Button, ButtonSecondary } from '../Button';
import { DialogLight } from '../Dialog';
import { Divider } from '../Divider';
import { VinInput } from '../FormElements';
import { HeadlineInverseSmall } from '../Headline';
import './UserVehicleEnrollDialog.scss';

export const UserVehicleEnrollDialog = () => (
  <DialogLight>
    <div className="user-vehicle-enroll-dialog__headline-section">
      <HeadlineInverseSmall>Enroll new vehicle</HeadlineInverseSmall>
      <span className="user-vehicle-enroll-dialog__close-icon sko-icon sko-icon-cross-thin"></span>
    </div>
    <Divider />
    <div className="user-vehicle-enroll-dialog__content-section">
      <div className="user-vehicle-enroll-dialog__content-box">
        <p className="user-vehicle-enroll-dialog__title">
          Enter your VIN number
        </p>
        <p className="user-vehicle-enroll-dialog__text">
          Enter the VIN (Vehicle Identification Number) of your vehicle. This allows SKODA to assign your order to your vehicle.
        </p>
        <div className="user-vehicle-enroll-dialog__input">
          <VinInput name="vin-no" label="VIN Number" />
        </div>
      </div>
      <AccordionElement 
        label="Where can I find my VIN number?"
        content="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer malesuada. Proin mattis lacinia justo. Nunc dapibus tortor vel mi dapibus sollicitudin. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Etiam ligula pede, sagittis quis, interdum ultricies, scelerisque eu."
      />
      <div className="user-vehicle-enroll-dialog__actions">
        <ButtonSecondary>Cancel</ButtonSecondary>
        <Button>Continue</Button>
      </div>
    </div>
  </DialogLight>
);
