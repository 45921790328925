import React from 'react';

import { NotLoggedInInformationBox } from '../../components';
import { InformationBoxLayout } from '../../layout';


export const NotLoggedInPage = () => (
  <InformationBoxLayout>
    <NotLoggedInInformationBox />
  </InformationBoxLayout>
);
