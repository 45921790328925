import React from 'react';
import './PaymentMethodOrderPriceSummary.scss';

export const PaymentMethodOrderPriceSummary = () => (
  <table className='payment-method-order-price-summary'>
    <tbody>
      <tr>
        <td className='payment-method-order-price-summary__item payment-method-order-price-summary__label'>
          Total without VAT
        </td>
        <td className='payment-method-order-price-summary__item payment-method-order-price-summary__value'>
          1 007 CZK
        </td>
      </tr>
      <tr>
        <td className='payment-method-order-price-summary__item payment-method-order-price-summary__label'>
          Total VAT (21%)
        </td>
        <td className='payment-method-order-price-summary__item payment-method-order-price-summary__value'>
          267 CZK
        </td>
      </tr>
      <tr>
        <td className='payment-method-order-price-summary__item payment-method-order-price-summary__label payment-method-order-price-summary__label--strong'>
          Total
        </td>
        <td className='payment-method-order-price-summary__item payment-method-order-price-summary__value payment-method-order-price-summary__value--strong'>
          1 274 CZK
        </td>
      </tr>
    </tbody>
  </table>
);
