import React from 'react';
import { UserAddressAdd, UserNavigation } from '../../components';
import { UserAccount } from '../../layout';

export const UserAccountAddressAdd = () => (
  <UserAccount
    navigation={<UserNavigation />}
    children={<UserAddressAdd />}
  />
);
