import React from 'react';
import { Header, Content, Footer, FooterNavigation } from '../../components';
import './UserAccount.scss';

export const UserAccount = ({ navigation, children }) => (
  <>
    <Header />
    <Content inverse={true}>
        <div className="user-account-page">
        <div className="user-account-page__nav-section">
          {navigation}
        </div>
        <div className="user-account-page__content-section">
          <div className="user-account-page__content-wrapper">
            {children}
          </div>
        </div>
      </div>
    </Content>
    <FooterNavigation />
    <Footer />
  </>
);
