import React from 'react';
import {  } from 'react-router-dom';
import { Button, ButtonLink, ButtonSecondary, HeadlineInverse, Input, Password } from '../';
import {  } from '../Button';
import './LoginForm.scss';


export const LoginForm = ({children}) => (
  <div className="login-form">
    <HeadlineInverse>Log In</HeadlineInverse>
    <div className="login-form__subtitle">to your ŠKODA Account</div>
    <form>
      <Input name="email" label="Email" /> 
      <Password name="password" label="Password" />
    </form>
    <div className="login-form__actions">
      <Button type="submit">Submit</Button><br />
      <ButtonLink>Create account</ButtonLink>
      
    </div>
  </div>
);