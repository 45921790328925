import React from 'react';
import { HeadlineInverse } from '../Headline';
import './ConfirmationActivationDetail.scss';

export const ConfirmationActivationDetail = () => (
  <div className="confirmation-activation-detail">
    <div className="confirmation-activation-detail__headline-section">
      <div className="confirmation-activation-detail__headline-section__skew">
        <div className="confirmation-activation-detail__headline-section__headline">
          <HeadlineInverse>Thank you for your order!</HeadlineInverse>
        </div>
        <p className="confirmation-activation-detail__headline-section__text">
          Your order
          <span className="confirmation-activation-detail__headline-section__text__bold">
            12345678
          </span>
          was successfully received.
        </p>
      </div>
    </div>
    <div className="confirmation-activation-detail__inner">
      <p className="confirmation-activation-detail__inner__title">
        Your new Ambient Lighting will be activated once your car connect to
        internet.
      </p>
      <p className="confirmation-activation-detail__inner__text">
        If vehicle is not connected to internet, follow these steps to activate
        your new function:
      </p>
      <div className="confirmation-activation-detail__inner__bullet-box">
        <span className="confirmation-activation-detail__inner__bullet-box__number">
          1
        </span>
        <p className="confirmation-activation-detail__inner__bullet-box__text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
      <div className="confirmation-activation-detail__inner__bullet-box">
        <span className="confirmation-activation-detail__inner__bullet-box__number">
          2
        </span>
        <p className="confirmation-activation-detail__inner__bullet-box__text">
          Viverra accumsan in nisl nisi scelerisque eu.
        </p>
      </div>
      <div className="confirmation-activation-detail__inner__bullet-box">
        <span className="confirmation-activation-detail__inner__bullet-box__number">
          3
        </span>
        <p className="confirmation-activation-detail__inner__bullet-box__text">
          Sed risus pretium quam vulputate dignissim suspendisse in.
        </p>
      </div>
    </div>
  </div>
);
