import React from 'react';
import { Checkbox } from '../FormElements';
import { Link } from '../Link';
import './UserConsentManagement.scss';

export const UserConsentManagement = () => (
  <div className="user-consent-management">
    <Checkbox
      label="ŠKODA AUTO can contact me for marketing activities, promotions, offers etc."
      checked={true}
    />
    <p className="user-consent-management__disclaimer">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam,  quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. <Link>Duis aute irure dolor</Link> in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur.
    </p>
  </div>
);
