import React from 'react';
import { BillingInfoDetail, ProgressBar } from '../../components';
import { ShoppingCartLayout } from '../../layout';

export const ShoppingCartBillinginfo = () => (
  <ShoppingCartLayout
    stepNavigation={<ProgressBar step={3} />}>
    <BillingInfoDetail />
  </ShoppingCartLayout>
);
