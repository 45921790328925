import React from 'react';
import { ProductDetail } from '../../components';
import { ProductLayout } from '../../layout';

export const ProductDetailWebApp = () => (
  <ProductLayout>
    <ProductDetail headline="McDonald’s On The Way" type="care-connect" price="290" currency="Kč" period="lifetime" imageSource="/assets/product-items/webapp-mc-donald-thumb.png" bgColor="#F8A623" textClass="negative">
        Díky službě McDonald’s On The Way si nově vychutnáš jídlo z McDonald’s kdykoliv tě napadne. Vyber si restauraci kdekoliv na tvé trase, objednej si jídlo, než tam dorazíš, vše bude připravené.
    </ProductDetail>
  </ProductLayout>
);
