import React from 'react';
import './Accordion.scss';

export const AccordionElement = ({label, content}) => (
  <div className="accordion-element">
    <input className="accordion-element__checkbox" type="checkbox" id="acc1" />
    <label className="accordion-element__label" htmlFor="acc1">
      {label}
      <span className="accordion-element__label-icon sko-icon sko-icon-beak-down"></span>
    </label>
    <div className="accordion-element__content">{content}</div>
  </div>
);
