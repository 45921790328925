import React from 'react';
import './CustomSelect.scss';
import { createLengthSelectSelectedIndicator, createCustomSelect, createLengthSelectOption } from './custom-select';

export class LengthSelectDisabled extends React.Component {
  state = {};

  constructor(){
    super();
    this.ref = React.createRef();
  }

  handleRerender = () => {
    if (this.ref.current) {
      createCustomSelect(this.ref.current, 'length-select', createLengthSelectSelectedIndicator, createLengthSelectOption);
      this.setState({rendered: true})
    }
  }

  // IN THYMELEAF use just the HTML below without RENDER button and the custom-select.js file, and follow the instructions there to update it to classic JS

  render() {
    return (
      <>
        <span className="length-select custom-select" ref={this.ref}>
          <select disabled>
            <option value="0" data-text="1 year">1 year</option>
            <option value="1" data-text="3 years">3 years</option>
            <option value="2" data-text="5 years">5 years</option>
            <option value="3" data-text="7 years">7 years</option>
          </select>
        </span>
        {!this.state.rendered && <button onClick={this.handleRerender}>RENDER</button>}
      </>
    )
  } 
};
