import React from 'react';
import { ProductDetail } from '../../components';
import { ProductLayout } from '../../layout';

export const ProductDetailFOD = () => (
  <ProductLayout>
    <ProductDetail headline="Ambient Lighting" type="ambient-lighting" price="199" currency="Kč" period="lifetime" imageSource="/assets/product-items/fod-ambient-lighting-full.png" bgColor="#2E640B">
      Funkce Ambient Lighting promění Váš interiér v noční oblohu nebo rozkvetlou louku. Stačí si vybrat, je to pouze na Vás.
    </ProductDetail>
  </ProductLayout>
);