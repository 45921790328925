import React from 'react';
import { CartNavigation, Header , Footer, FooterNavigation} from '../../components';
import './ShoppingCartLayout.scss';

export const ShoppingCartLayout = ({ stepNavigation, children }) => (
  <>
    <Header />
    <content className="shopping-cart-layout">
      <CartNavigation>
        {stepNavigation}
      </CartNavigation>
      <div className="shopping-cart-layout__content-section">
        <div className="shopping-cart-layout__content-wrapper">
          {children}
        </div>
      </div>
    </content>
    <FooterNavigation />
    <Footer />
  </>
);
