import React from "react";
import { Divider } from "../Divider";
import CardsImage from "../../static/assets/cards.png";
import { Button, ButtonSecondaryBack } from "../Button";
import "./PaymentMethodIndentPayment.scss";

export const PaymentMethodIndentPayment = () => (
  <div className="payment-method-indent-payment">
    <label className="payment-method-indent-payment__selection-item">
      <input type="radio" name="radio"></input>
      <div className="payment-method-indent-payment__selection-item__background">
        <div className="payment-method-indent-payment__selection-item__container">
          <p className="payment-method-indent-payment__selection-item__text">
            Card payment online
          </p>
          <p className="payment-method-indent-payment__selection-item__price">
            0 CZK
          </p>
        </div>
        <span className="payment-method-indent-payment__selection-item__checkmark"></span>
        <p className="payment-method-indent-payment__info-text">
          Following credit cards are being accepted:
        </p>
        <img
          className="payment-method-indent-payment__image"
          src={CardsImage}
          alt="payment-cards-illustrative"
        />
      </div>
    </label>
    <label className="payment-method-indent-payment__selection-item">
      <input type="radio" name="radio"></input>
      <div className="payment-method-indent-payment__selection-item__background">
        <div className="payment-method-indent-payment__selection-item__container">
          <p className="payment-method-indent-payment__selection-item__text">
            Bank transfer
          </p>
          <p className="payment-method-indent-payment__selection-item__price">
            0 CZK
          </p>
        </div>
        <span className="payment-method-indent-payment__selection-item__checkmark"></span>
      </div>
    </label>
    <Divider></Divider>
    <div className="payment-method-indent-payment__info-box">
      <span data-icon="" class="icon icon-info"></span>
      <p className="payment-method-indent-payment__info-box__text">
        The service will be activated after successful payment. Pair your
        vehicle by connecting it to internet.
      </p>
    </div>
    <div className="payment-method-indent-payment__button-section">
      <ButtonSecondaryBack>Cart</ButtonSecondaryBack>
      <Button>Continue</Button>
    </div>
  </div>
);
