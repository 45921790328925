import React from 'react';
import { HeadlineInverse } from '../Headline';
import { ShoppingCartProductSummary } from '../ShoppingCartProductSummary';
import { OrderPriceSummary } from '../OrderPriceSummary';
import { Divider } from '../Divider';
import { Button, ButtonSecondaryBack} from '../Button';
import './ShoppingCartDetail.scss';

export const ShoppingCartDetail = () => (
  <div className="shopping-cart-detail">
    <div className="shopping-cart-detail__headline-section">
      <HeadlineInverse>Shopping Cart</HeadlineInverse>
    </div>
    <ul className="shopping-cart-detail__summary-section">
      <Divider />
      <li className="shopping-cart-detail__item">
        <ShoppingCartProductSummary />
      </li>
      <Divider />
      <li className="shopping-cart-detail__item">
        <ShoppingCartProductSummary />
      </li>
      <Divider />
    </ul>
    <div className="shopping-cart-detail__price-section">
      <OrderPriceSummary />
    </div>
    <div className="shopping-cart-detail__button-section">
      <ButtonSecondaryBack>Back to the store</ButtonSecondaryBack>
      <Button>Checkout</Button>
    </div>
  </div>
);
