import React from 'react';
import { Button, ButtonSecondary } from '../Button';
import { CheckboxBold, Input } from '../FormElements';
import './UserInformationForm.scss';

export const UserInformationFormCollapsed = () => (
  <form className="user-information-form">
    <div className="user-information-form__line">
      <Input name="first-name" label="First name" /> 
    </div>
    <div className="user-information-form__line">
      <Input name="last-name" label="Last name" /> 
    </div>
    <div className="user-information-form__line">
      <Input name="phone" label="Phone number" /> 
    </div>
    <div className="user-information-form__line user-information-form__line--spaced">
      <CheckboxBold label="I'm buying as a company" checked={false} /> 
    </div>
    <div className="user-information-form__actions">
      <ButtonSecondary>Cancel</ButtonSecondary>
      <Button type="submit">Save changes</Button>
    </div>
  </form>
);
