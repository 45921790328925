import React from 'react';
import { HeadlineInverse, HeadlineInverseSmall } from '../Headline';
import './Error.scss';

export const Error = () => (
  <div className="error">
    <HeadlineInverse>Error 500</HeadlineInverse>
    <HeadlineInverseSmall>We are sorry, an error occurred on the server.</HeadlineInverseSmall>
    <p className="error__text">
      Error has been reported and we will work to resolve it immediately. Please try again in a moment.
    </p>
    <div className="text-center">
      <a className="button" href="/">Back to homepage</a>
    </div>
  </div>
);