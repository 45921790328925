import React from 'react';
import { DownloadLink } from '../Link';
import './OrderDetailGrid.scss';

export const OrderDetailGrid = () => (
  <div className="order-detail-grid">
    <div className="order-detail-grid__row">
      <div className="order-detail-grid__box order-detail-grid__box--row">
        <div className="order-detail-grid__item">
          <span className="order-detail-grid__item--strong">Created on: </span>
          7. 3. 2019
        </div>
        <div className="order-detail-grid__item">
          <span className="order-detail-grid__item--strong">Payment: </span>
          Card payment online
        </div>
      </div>
    </div>
    <div className="order-detail-grid__row">
      <div className="order-detail-grid__box order-detail-grid__box--half">
        <div className="order-detail-grid__item order-detail-grid__item--strong order-detail-grid__item--spaced">Billing address</div>
        <div className="order-detail-grid__item order-detail-grid__item--strong">Lubomír Veselý</div>
        <div className="order-detail-grid__item">Národní 21</div>
        <div className="order-detail-grid__item">110 00 Praha 1</div>
        <div className="order-detail-grid__item">Czech Republic</div>
      </div>
      <div className="order-detail-grid__box order-detail-grid__box--half">
        <div className="order-detail-grid__item order-detail-grid__item--spaced">Your email</div>
        <div className="order-detail-grid__item order-detail-grid__item--strong order-detail-grid__item--spaced">l.vesely@seznam.cz</div>
        <div className="order-detail-grid__item order-detail-grid__item--spaced">Phone</div>
        <div className="order-detail-grid__item order-detail-grid__item--strong order-detail-grid__item--spaced">+420 724 558 999</div>
      </div>
    </div>
    <div className="order-detail-grid__row">
      <div className="order-detail-grid__box">
        <div className="order-detail-grid__item order-detail-grid__item--strong order-detail-grid__item--spaced">Documents</div>
        <div className="order-detail-grid__box--row">
          <div className="order-detail-grid__item"><DownloadLink>Invoice no. 1234567</DownloadLink></div>
          <div className="order-detail-grid__item"><DownloadLink>Tax Confirmation</DownloadLink></div>
          <div className="order-detail-grid__item"><DownloadLink>Another Document</DownloadLink></div>
        </div>
      </div>
    </div>
  </div>
);
