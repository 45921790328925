import React from 'react';
import './VehicleInformation.scss';

export const VehicleInformation = ({ compatible }) => (
  <span className="vehicle-information">
    <img className="vehicle-information__image" src="assets/car.png" />
    <div className={`vehicle-information__badge vehicle-information__badge--${compatible ? 'success' : 'error'} sko-icon sko-icon-${compatible ? 'checkmark' : 'cross'}`} />
    <p className="vehicle-information__name">Fabia Classic</p>
    <p className="vehicle-information__active">{compatible ? 'Active till 14. 2. 2020' : 'Not compatible'}</p>
  </span>
);
