import React from 'react';
import ProductImage from '../../static/assets/product.png';
import './PaymentMethodOrderProductSummary.scss';

export const PaymentMethodOrderProductSummary = () => (
  <div className='payment-method-order-product-summary'>
    <img className='payment-method-order-product-summary__image' src={ProductImage} alt="product-illustrative" />
    <div className='payment-method-order-product-summary__info-section'>
      <p className='payment-method-order-product-summary__product-name'>Infotainment Online</p>
      <p className='payment-method-order-product-summary__car-type'>SCALA Ambition</p>
      <p className='payment-method-order-product-summary__car-vin'>VIN: 3CR3684RT59Y2VA15</p>
      <p className='payment-method-order-product-summary__timing'>1 year prolongation</p>
    </div>
    <div className='payment-method-order-product-summary__price'>1 274 CZK</div>
  </div>
);
