import React from 'react';
import { CartButton } from '../CartButton';
import './Header.scss';

export const UserHeader = ({isLogged,items}) => (
  <ul className="header__user">
    {isLogged === true ?
      <>
        <li className="header__user__item">
          <a href="/" className="header__user__button header__user__button--active">
            <span className="sko-icon sko-icon-user"></span> Lubomir
          </a>
        </li>
        <li>
          <CartButton items={items} />
        </li>
      </>
    :
      <a href="/" className="header__user__button">
        <span className="sko-icon sko-icon-user-empty"></span> Sign In
      </a>
    }
  </ul>
);