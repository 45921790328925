import React from 'react';

import { NotManyCompatibleVehiclesInformationBox } from '../../components';
import { InformationBoxLayout } from '../../layout';


export const NotManyCompatibleVehiclesPage = () => (
  <InformationBoxLayout>
    <NotManyCompatibleVehiclesInformationBox />
  </InformationBoxLayout>
);
