import React from 'react';
import { UserAddresses, UserNavigation } from '../../components';
import { UserAccount } from '../../layout';

export const UserAccountAddresses = () => (
  <UserAccount
    navigation={<UserNavigation />}
    children={<UserAddresses />}
  />
);
