import React from 'react';
import { Link } from "react-router-dom";
import { UserHeader } from './UserHeader';
import { Navigation } from '../Navigation/Navigation';
import './Header.scss';
import LogoImage from './Logo.png';

const Logo = () => (
  <Link to="/" className="header__logo"><img src={LogoImage} alt="Škoda Connect Store"/></Link>
);

export const Header = () => (
  <header className="header">
    <nav className="header__wrapper">
      <Logo />
      <Navigation />
      <UserHeader isLogged={false} items={0} />
    </nav>
  </header>
);