import React from 'react';
import { UserNavigation, UserVehicles, UserVehicleEnrollConfirmDialog } from '../../components';
import { UserAccount } from '../../layout';

export const UserAccountVehiclesWithEnrollConfirm = () => (
  <>
    <UserAccount
      navigation={<UserNavigation />}
      children={<UserVehicles />}
    />
    <UserVehicleEnrollConfirmDialog />
  </>
);
