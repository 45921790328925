import React from 'react';
import { Button, ButtonSecondary } from '../Button';
import { DialogLight } from '../Dialog';
import { Divider } from '../Divider';
import { HeadlineInverseSmall } from '../Headline';
import './UserAddressDeleteDialog.scss';

export const UserAddressDeleteDialog = () => (
  <DialogLight>
    <div className="user-address-delete-dialog__headline-section">
      <HeadlineInverseSmall>Delete address</HeadlineInverseSmall>
      <span className="user-address-delete-dialog__close-icon sko-icon sko-icon-cross-thin"></span>
    </div>
    <Divider />
    <div className="user-address-delete-dialog__content-section">
      <HeadlineInverseSmall>Do you want to delete this address?</HeadlineInverseSmall>
      <Button>Yes delete</Button>
      <ButtonSecondary>Cancel</ButtonSecondary>
    </div>
  </DialogLight>
);
