import React from 'react';
import { ButtonThin, ButtonLinkBack } from '../Button';
import { HeadlineInverse } from '../Headline';
import { Divider } from '../Divider';
import { OrderDetailGrid } from '../OrderDetailGrid';
import { OrderProductSummary } from '../OrderProductSummary';
import { OrderPriceSummary } from '../OrderPriceSummary';
import './OrderDetail.scss';

export const OrderDetail = () => (
  <div className="order-detail">
    <div className="order-detail__link-back-section">
      <ButtonLinkBack>All orders</ButtonLinkBack>
    </div>
    <div className="order-detail__headline-section">
      <HeadlineInverse>1298523</HeadlineInverse>
    </div>
    <Divider />
    <div className="order-detail__status-section">
      <span className="order-detail__status-icon order-detail__status-icon--success sko-icon sko-icon-checkmark"></span>
      <span className="order-detail__status order-detail__status--success">Order processed 7. 3. 2019</span>
      <ButtonThin>Download invoice</ButtonThin>
    </div>
    <Divider />
    <div className="order-detail__grid-section">
      <OrderDetailGrid />
    </div>
    <ul className="order-detail__summary-section">
      <Divider />
      <li className="order-detail__summary-item">
        <OrderProductSummary />
      </li>
      <Divider />
    </ul>
    <div className='order-detail__price-section'>
      <OrderPriceSummary />
    </div>
  </div>
);
