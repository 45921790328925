import React from 'react';
import { UserAddressEdit, UserNavigation } from '../../components';
import { UserAccount } from '../../layout';

export const UserAccountAddressEdit = () => (
  <UserAccount
    navigation={<UserNavigation />}
    children={<UserAddressEdit />}
  />
);
